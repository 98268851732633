<template>
  <router-view/>
</template>

<style lang="scss">
*{
  padding: 0px;
  margin: 0px;
}
html,body,#app{
  width: 100%;
  height: 100%;
}
</style>
