import {createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw} from 'vue-router'


const routes: Array<RouteRecordRaw> = [

  {
    path: '/',
    redirect: '/main' // 将默认路由重定向到 '/home'
  },
  {
    path: '/main',
    name: 'main',
    component: () => import(/* webpackChunkName: "about" */ '../views/MainView.vue'),
    meta: {
      requiresAuth: true,
      title: '首页'
    },
    children: [
      {
        path: '/mainplane',
        name: 'mainplane',
        component: () => import(/* webpackChunkName: "about" */ '../views/MainPlane.vue')
      },
      {
        path: '/news',
        name: 'news',
        component: () => import(/* webpackChunkName: "about" */ '../views/NewView.vue'),
        children: [
          {
            path: '/options',
            name: 'options',
            component: () => import(/* webpackChunkName: "about" */ '../views/Options.vue')
          },
          {
            path: '/plane',
            name: 'plane',
            component: () => import(/* webpackChunkName: "about" */ '../views/Plane.vue')
          },
          {
            path: '/partner',
            name: 'partner',
            component: () => import(/* webpackChunkName: "about" */ '../views/Partner.vue')
          },
          {
            path: '/outh',
            name: 'outh',
            component: () => import(/* webpackChunkName: "about" */ '../views/Outh.vue')
          },{
            path: '/prc',
            name: 'prc',
            component: () => import(/* webpackChunkName: "about" */ '../views/Prc.vue')
          },
          {
            path: '/datainfo',
            name: 'datainfo',
            component: () => import(/* webpackChunkName: "about" */ '../views/datainfo.vue')
          },
        ]
      },

      {
        path: '/process',
        name: 'process',
        component: () => import(/* webpackChunkName: "about" */ '../views/Process.vue')
      },

    ]
  },
  {
    path: '/video',
    name: 'video',
    component: () => import(/* webpackChunkName: "about" */ '../views/Video.vue'),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
